import axios from 'axios'
const url = 'https://gwinn.com.ar/API/v1/'

const configToken = (token) => {
  return {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
}

export const getAllNationalities = async (token) => {
  try {
    const res = await axios.get(url + 'nationalities.php', configToken(token))
    return res
  } catch (err) {
    throw err.response
  }
}

export const getAllIVAConditions = async (token) => {
  try {
    const res = await axios.get(url + 'ivaConditions.php', configToken(token))
    return res
  } catch (err) {
    throw err.response
  }
}

export const getAllBanks = async (token) => {
  try {
    const res = await axios.get(url + 'banks.php', configToken(token))
    return res
  } catch (err) {
    throw err.response
  }
}

export const getAllBankAccountTypes = async (token) => {
  try {
    const res = await axios.get(url + 'bankAccountTypes.php', configToken(token))
    return res
  } catch (err) {
    throw err.response
  }
}

export const getAllCurrencies = async (token) => {
  try {
    const res = await axios.get(url + 'currencies.php', configToken(token))
    return res
  } catch (err) {
    throw err.response
  }
}
