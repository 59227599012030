export default class SorterHelper {
  static sortCustomers (customers) {
    const customersAux = JSON.parse(JSON.stringify(customers))
    const customersSorted = customersAux.sort((a, b) => {
      const aCompleteName = a.name + ' ' + a.lastname
      const bCompleteName = b.name + ' ' + b.lastname
      if (aCompleteName < bCompleteName) { return -1 }
      if (aCompleteName > bCompleteName) { return 1 }
      return 0
    })
    return customersSorted
  }

  static sortAgents (agents) {
    const agentsAux = JSON.parse(JSON.stringify(agents))
    const agentsSorted = agentsAux.sort((a, b) => {
      const aCompleteName = (a.userType.id === 1) ? a.name + ' ' + a.lastname : a.businessData.name
      const bCompleteName = (b.userType.id === 1) ? b.name + ' ' + b.lastname : b.businessData.name
      // const bCompleteName = b.name + ' ' + b.lastname
      if (aCompleteName < bCompleteName) { return -1 }
      if (aCompleteName > bCompleteName) { return 1 }
      return 0
    })
    return agentsSorted
  }
}
