<template>
  <div id="customer">
    <v-toolbar
      flat
    >
      <v-btn class="ml-1" :to="'/clientes'"
             icon>
        <v-icon>mdi-keyboard-backspace</v-icon>
      </v-btn>
      <v-divider
        class="mx-4"
        inset
        vertical
      ></v-divider>
      <v-toolbar-title>Informacion del cliente</v-toolbar-title>
      <v-btn
        dark
        color=primary
        class="mb-2 save-btn"
        @click="saveCustomer"
      >
        Guardar
      </v-btn>
    </v-toolbar>

    <div class="customer-info pa-8 d-flex" v-if="customer !== null">
      <div class="col-left">
        <v-card class="pa-4 mb-8" v-if="customersIsEnterprise && customer.businessData !== undefined">
          <h4>Datos Persona Juridica</h4>
          <v-row class="mt-6">
            <v-col>
              <v-text-field label="Razon Social" v-model="customer.businessData.name"></v-text-field>
            </v-col>
            <v-col>
              <v-text-field label="Cuit" v-model="customer.businessData.cuit" :rules="[v => /^\d+$/.test(v) || 'Solo se permiten números']"></v-text-field>
            </v-col>
            <v-col>
              <v-text-field label="Domicilio real" v-model="customer.businessData.address"></v-text-field>
            </v-col>
            <v-col>
              <v-text-field label="Código postal" v-model="customer.businessData.postalCode"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field label="Localidad" v-model="customer.businessData.locality"></v-text-field>
            </v-col>
            <v-col>
              <!-- <v-text-field label="Provincia" v-model="customer.businessData.province"></v-text-field> -->
              <v-select label="Provincia" :items="provincesItems" v-model="customer.businessData.province"></v-select>
            </v-col>
            <v-col>
              <v-text-field label="Email" v-model="customer.businessData.email" :rules="emailRules"></v-text-field>
            </v-col>
            <v-col>
              <!-- <v-text-field label="Teléfono" v-model="customer.businessData.phone1"></v-text-field> -->
              <vue-tel-input v-model="customer.businessData.phone1" class="vue-tel-input-custom"></vue-tel-input>
            </v-col>
          </v-row>
        </v-card>
        <v-card class="pa-4">
          <h4>{{ customersIsEnterprise ? 'Datos representante legal' : 'Datos Persona Fisica'}}</h4>
          <v-row class="mt-6">
            <v-col>
              <v-text-field label="Nombre" v-model="customer.name" @blur="capitalizeName"></v-text-field>
            </v-col>
            <v-col>
              <v-text-field label="Apellido" v-model="customer.lastname" @blur="capitalizeLastName"></v-text-field>
            </v-col>
            <v-col>
              <v-text-field label="DNI" v-model="customer.dni" :rules="[v => /^\d+$/.test(v) || 'Solo se permiten números']" @input="filterNumbers"></v-text-field>
            </v-col>
            <v-col>
              <v-text-field label="Email" v-model="customer.email" :rules="emailRules"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select label="Nacionalidad" :items="nationalitiesItems" v-model="customer.nationality.id"></v-select>
            </v-col>
            <v-col>
              <v-text-field label="Fecha de Nacimiento (25-05-1990)" v-model="customer.birthDate" type="date"></v-text-field>
<!--              <v-date-picker v-model="customer.birthDate"></v-date-picker>-->
            </v-col>
            <v-col>
              <!-- <v-text-field label="Estado Civil" v-model="customer.civilStatus"></v-text-field> -->
              <v-select label="Estado civil" :items="estadoCivilItems" v-model="customer.civilStatus"></v-select>
            </v-col>
            <v-col>
              <!-- <v-text-field label="Teléfono" v-model="customer.phone1"></v-text-field> -->
              <vue-tel-input v-model="customer.phone1" class="vue-tel-input-custom"></vue-tel-input>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field label="Domicilio" v-model="customer.address"></v-text-field>
            </v-col>
            <v-col>
              <v-text-field label="Localidad" v-model="customer.locality"></v-text-field>
            </v-col>
            <v-col>
              <v-text-field label="Codigo postal" v-model="customer.postalCode"></v-text-field>
            </v-col>
            <v-col>
              <!-- <v-text-field label="Provincia" v-model="customer.province"></v-text-field> -->
              <v-select label="Provincia" :items="provincesItems" v-model="customer.province"></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field label="Ocupacion/Cargo" v-model="customer.occupation"></v-text-field>
            </v-col>
            <v-col>
              <v-select label="Resp. Frente AFIP" :items="ivaConditionsItems"
                        v-model="customer.ivaStatus.id"></v-select>
            </v-col>
            <v-col>
              <v-select label="Sujeto Obligado" :items="obligatedSubjectItems"
                        v-model="customer.obligatedSubject"></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" sm="3">
              <v-select label="PEP" :items="pepItems" v-model="customer.pep"></v-select>
            </v-col>
            <v-col cols="6" sm="3">
              <v-text-field label="Password" v-model="customer.password"></v-text-field>
            </v-col>
          </v-row>
        </v-card>
        <v-card class="pa-4 mt-8">
          <div class="d-flex flex-row align-center justify-space-between">
            <h4>Datos cuenta bancaria</h4>
            <v-btn
              dark
              color="primary"
              @click="addBankAccount"
            >
              Agregar
            </v-btn>
          </div>

          <div v-for="(bankAccount, index) in customer.bankAccounts" :key="index" class="bank-account-container">
            <v-row class="mt-6">
              <v-col>
                <v-text-field label="Titular" v-model="bankAccount.owner"></v-text-field>
              </v-col>
              <v-col>
                <v-text-field label="Nr de cuenta" v-model="bankAccount.accountNumber"></v-text-field>
              </v-col>
              <v-col>
                <v-text-field label="CBU" v-model="bankAccount.cbu"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field label="Sucursal" v-model="bankAccount.branchOffice"></v-text-field>
              </v-col>
              <v-col>
                <v-select label="Banco" :items="banksItems" v-model="bankAccount.bank.id"></v-select>
              </v-col>
              <v-col>
                <v-select label="Tipo de cuenta" :items="bankAccountTypesItems"
                          v-model="bankAccount.accountType.id"></v-select>
              </v-col>
              <v-col>
                <v-select label="Moneda" :items="currenciesItems" v-model="bankAccount.currency.id"></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col></v-col>
              <v-col></v-col>
              <v-col></v-col>
              <v-col class="text-end">
                <v-btn
                  dark
                  color="primary"
                  @click="deleteAccount(index)"
                >
                  Eliminar
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </div>
      <div class="col-right">
        <v-card color=primary class="pa-4 ml-8 mb-8 d-flex flex-nowrap align-center">
          <h4 class="mr-4">Puntos GWINN: </h4>
          <v-text-field label="Puntos" v-model="customer.gwinnPoints"></v-text-field>
        </v-card>
        <v-card class="pa-4 ml-8 my-8">
          <h4 class="mr-4 mb-6">Agente a cargo</h4>
          <!-- <v-text-field label="Agente" v-model="customer.agent"></v-text-field> -->
          <v-select label="Agente a cargo" :items="agentsItems" v-model="customer.agent"></v-select>
        </v-card>
        <v-card class="pa-4 ml-8 my-8">
          <h4 class="mr-4 mb-6">Cliente referido</h4>
          <v-select label="Cliente referido" :items="customersItems" v-model="customer.referal"></v-select>
        </v-card>
        <v-card class="pa-4 ml-8 my-8">
          <h4 class="mr-4 mb-6">Código cliente</h4>
          <v-text-field label="Código cliente" v-model="customer.code"></v-text-field>
        </v-card>
        <v-card class="pa-4 ml-8 my-8">
          <h4 class="mr-4 mb-6">Tipo de cliente</h4>
          <v-select label="Tipo de cliente" :items="userTypesItems"
                    v-model="customer.userType.id"></v-select>
        </v-card>
        <v-card class="pa-4 ml-8 my-8">
          <h4 class="mr-4 mb-6">Links de accesos</h4>
          <span>Google drive</span>
          <v-text-field label="Enlace" v-model="customer.driveLink"></v-text-field>
          <v-divider class="dashed mb-4"></v-divider>
          <span>Sistema de fidelizacion</span>
          <v-text-field label="Enlace" v-model="customer.fidelizacionLink"></v-text-field>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { createUser, updateUser } from '../services/users'
import { getAllAgents } from '../services/agents'
import {
  getAllNationalities,
  getAllIVAConditions,
  getAllCurrencies,
  getAllBanks,
  getAllBankAccountTypes
} from '../services/global'
import ValidationHelper from '../helpers/ValidationHelper'
import SorterHelper from '../helpers/SorterHelper'

export default {
  name: 'Customer',
  data () {
    return {
      userTypesItems: [],
      customersItems: [],
      nationalities: [],
      nationalitiesItems: [],
      estadoCivilItems: ['Soltero', 'Casado', 'Divorciado', 'Viudo', 'Otro'],
      ivaConditions: [],
      ivaConditionsItems: [],
      banks: [],
      banksItems: [],
      bankAccountTypes: [],
      bankAccountTypesItems: [],
      currencies: [],
      currenciesItems: [],
      agents: [],
      agentsItems: [],
      provincesItems: [
        'Buenos Aires (CABA)',
        'Buenos Aires (Interior)',
        'Catamarca',
        'Chaco',
        'Chubut',
        'Cordoba',
        'Corrientes',
        'Entre Rios',
        'Formosa',
        'Jujuy',
        'La Pampa',
        'La Rioja',
        'Mendoza',
        'Misiones',
        'Neuquen',
        'Rio Negro',
        'Salta',
        'San Juan',
        'San Luis',
        'Santa Cruz',
        'Santa Fe',
        'Santiago del Estero',
        'Tierra del Fuego',
        'Tucuman'
      ],
      customer: null,
      obligatedSubjectItems: [
        {
          text: 'SI',
          value: 1
        },
        {
          text: 'NO',
          value: 0
        }
      ],
      pepItems: [
        {
          text: 'SI',
          value: 1
        },
        {
          text: 'NO',
          value: 0
        }
      ],
      showBirthDateDatepicker: null,
      emailRules: [
        v => !!v || 'El correo electrónico es requerido',
        v => /.+@.+\..+/.test(v) || 'El correo electrónico debe ser válido'
      ]
    }
  },
  computed: {
    ...mapGetters(['customers', 'userToken']),
    customersIsEnterprise () {
      return this.customers !== null && this.customer.userType?.id === 2
    }
  },
  methods: {
    ...mapActions(['handleLoading']),
    initEmptyCustomer () {
      this.customer = {
        address: '',
        bankAccounts: [],
        birthDate: '',
        businessData: {
          address: '',
          cuit: '',
          email: '',
          locality: '',
          name: '',
          phone1: '',
          phone2: '',
          postalCode: '',
          province: ''
        },
        civilStatus: '',
        dni: '',
        driveLink: '',
        email: '',
        fidelizacionLink: 'https://gwinn.tiendadepuntos.com/',
        gwinnPoints: 0,
        ivaStatus: {},
        lastname: '',
        locality: '',
        name: '',
        nationality: {},
        obligatedSubject: 0,
        occupation: '',
        password: '',
        pep: 0,
        phone1: '',
        phone2: '',
        postalCode: '',
        province: '',
        referal: '',
        agent: '',
        userType: {
          id: 2
        },
        code: ''
      }
    },
    initBusinessData () {
      this.customer.businessData = {
        address: '',
        cuit: '',
        email: '',
        locality: '',
        name: '',
        phone1: '',
        phone2: '',
        postalCode: '',
        province: ''
      }
    },
    filterNumbers (value) {
      this.customer.dni = value.replace(/[^0-9]/g, '')
    },
    capitalizeName () {
      if (this.customer.name) {
        this.customer.name = this.capitalizer(this.customer.name)
      }
    },
    capitalizeLastName () {
      if (this.customer.lastname) {
        this.customer.lastname = this.capitalizer(this.customer.lastname)
      }
    },
    capitalizer (string) {
      return string.toLowerCase()
        .replace(/\b\w/g, char => char.toUpperCase())
    },
    passValidations () {
      const validation = new ValidationHelper()
      const emailValidate = validation.validateEmail(this.customer.email)
      const businessEmailValidate = validation.validateEmail(this.customer.businessData.email)
      return (this.customersIsEnterprise) ? (emailValidate && businessEmailValidate) : emailValidate
    },
    saveCustomer () {
      if (this.passValidations()) {
        if (this.customer.id !== undefined) {
          this.editCustomer()
        } else {
          this.addCustomer()
        }
      } else {
        alert('Complete correctamente todos los campos')
      }
    },
    async addCustomer () {
      try {
        this.handleLoading(true)
        const res = await createUser(this.userToken, this.customer)
        alert(res.data.response)
        // console.log(res)
      } catch (error) {
        console.log(error)
      } finally {
        this.handleLoading(false)
        // this.handleAlert({ value: true, text: this.alertMessage, color: this.alertColor })
      }
    },
    async editCustomer () {
      try {
        this.handleLoading(true)
        const res = await updateUser(this.userToken, this.customer)
        alert(res.data.response)
        // console.log(res)
      } catch (error) {
        console.log(error)
      } finally {
        this.handleLoading(false)
        // this.handleAlert({ value: true, text: this.alertMessage, color: this.alertColor })
      }
    },

    addBankAccount () {
      this.customer.bankAccounts.push({
        accountNumber: '',
        accountType: {
          id: ''
        },
        bank: { id: '' },
        branchOffice: '',
        cbu: '',
        currency: { id: '' },
        owner: ''
      })
    },
    deleteAccount (index) {
      this.customer.bankAccounts.splice(index, 1)
    },

    initUserTypesItems () {
      this.userTypesItems = [
        {
          value: 1,
          text: 'Persona física'
        },
        {
          value: 2,
          text: 'Persona jurídica'
        }
      ]
    },
    initCustomersItems () {
      const customersAux = JSON.parse(JSON.stringify(this.customers))
      const customersSorted = customersAux.sort((a, b) => {
        const aCompleteName = a.name + ' ' + a.lastname
        const bCompleteName = b.name + ' ' + b.lastname
        if (aCompleteName < bCompleteName) { return -1 }
        if (aCompleteName > bCompleteName) { return 1 }
        return 0
      })
      this.customersItems = []
      this.customersItems.push({
        value: '',
        text: 'Elija un cliente referido'
      })
      customersSorted.forEach((c, i) => {
        this.customersItems.push({
          value: c.id,
          text: c.name + ' ' + c.lastname
        })
      })
    },
    initAgentsItems () {
      this.agentsItems = []
      this.agentsItems.push({
        value: '',
        text: 'Elija un cliente referido'
      })
      this.agents.forEach((a, i) => {
        const completeName = (a.userType.id === 1) ? a.name + ' ' + a.lastname : a.businessData.name
        this.agentsItems.push({
          value: a.id,
          text: completeName
        })
      })
    },
    selectCustomerReferal () {
      if (this.customer?.referal) {

      }
    },
    async getNationalities () {
      try {
        // this.handleLoading(true)
        const res = await getAllNationalities()
        this.nationalities = this.sortNationalitiesArgFirst(res.data.data)
      } catch (error) {
        console.log(error)
      } finally {
        // this.handleLoading(false)
        // this.handleAlert({ value: true, text: this.alertMessage, color: this.alertColor })
      }
    },
    sortNationalitiesArgFirst (nationalities) {
      const firstID = 10
      const nationalitiesSorted = nationalities.sort(function (x, y) { return x.id === firstID ? -1 : y.id === firstID ? 1 : 0 })
      return nationalitiesSorted
    },
    async getIVAConditions () {
      try {
        // this.handleLoading(true)
        const res = await getAllIVAConditions()
        this.ivaConditions = res.data.data
      } catch (error) {
        console.log(error)
      } finally {
        // this.handleLoading(false)
        // this.handleAlert({ value: true, text: this.alertMessage, color: this.alertColor })
      }
    },
    async getBanks () {
      try {
        // this.handleLoading(true)
        const res = await getAllBanks()
        this.banks = res.data.data
      } catch (error) {
        console.log(error)
      } finally {
        // this.handleLoading(false)
        // this.handleAlert({ value: true, text: this.alertMessage, color: this.alertColor })
      }
    },
    async getBankAccountTypes () {
      try {
        // this.handleLoading(true)
        const res = await getAllBankAccountTypes()
        this.bankAccountTypes = res.data.data
      } catch (error) {
        console.log(error)
      } finally {
        // this.handleLoading(false)
        // this.handleAlert({ value: true, text: this.alertMessage, color: this.alertColor })
      }
    },
    async getCurrencies () {
      try {
        // this.handleLoading(true)
        const res = await getAllCurrencies()
        this.currencies = res.data.data
      } catch (error) {
        console.log(error)
      } finally {
        // this.handleLoading(false)
        // this.handleAlert({ value: true, text: this.alertMessage, color: this.alertColor })
      }
    },
    async getAgents () {
      try {
        // this.handleLoading(true)
        const res = await getAllAgents()
        this.agents = SorterHelper.sortAgents(res.data.data)
      } catch (error) {
        console.log(error)
      } finally {
        // this.handleLoading(false)
        // this.handleAlert({ value: true, text: this.alertMessage, color: this.alertColor })
      }
    },
    transformToSelect (array, fieldName, initItem) {
      const arrayItems = []
      if (initItem) {
        arrayItems.push(initItem)
      }
      array.forEach(data => {
        let text = ''
        if (typeof fieldName === 'string') {
          text = data[fieldName]
        }
        if (typeof fieldName === 'object') {
          fieldName.forEach(f => {
            text += data[f] + ' '
          })
        }
        arrayItems.push({
          value: data.id,
          text: text
        })
      })
      return arrayItems
    }
  },
  async mounted () {
    this.handleLoading(true)
    await this.getNationalities()
    await this.getIVAConditions()
    await this.getBanks()
    await this.getBankAccountTypes()
    await this.getCurrencies()
    await this.getAgents()
    this.initUserTypesItems()
    this.initCustomersItems()
    this.initAgentsItems()
    this.handleLoading(false)

    const idCustomer = this.$route.params.id
    if (idCustomer !== undefined) {
      if (this.customers.length === 0) {
        this.$router.push({ path: '/clientes' })
      }
      const index = this.customers.findIndex(c => c.id === parseInt(idCustomer))
      if (index !== -1) {
        this.customer = this.customers[index]

        // COMO SE CAMBIO EL TEMA DEL AGENTE, TENGO QUE APLICAR ESTA LOGICA PARA TRANSFORMAR CORRECTAMENTE A ID AGENTE
        if (this.customer.agent) {
          const agentId = parseFloat(this.customer.agent)
          const agentIdIsValid = !isNaN(agentId) && isFinite(agentId) && String(agentId) === this.customer.agent.trim()
          if (agentIdIsValid) {
            this.customer.agent = agentId
          }
        }

        this.selectCustomerReferal()
      }

      if (this.customer.fidelizacionLink === '') {
        this.customer.fidelizacionLink = 'https://gwinn.tiendadepuntos.com/'
      }
    } else {
      this.initEmptyCustomer()
    }
  },
  watch: {
    customer: {
      deep: true,
      handler () {
        if (this.customer.businessData === null) {
          this.initBusinessData()
        }
      }
    },
    'customer.name': {
      handler () {
        this.customer.password = this.customer.name + '2024'
      }
    },
    customers: {
      deep: true,
      handler () {
        this.initCustomersItems()
      }
    },
    nationalities: {
      deep: true,
      handler () {
        this.nationalitiesItems = this.transformToSelect(this.nationalities, 'name', {
          value: '',
          text: 'Elija nacionalidad'
        })
      }
    },
    ivaConditions: {
      deep: true,
      handler () {
        this.ivaConditionsItems = this.transformToSelect(this.ivaConditions, 'name', {
          value: '',
          text: 'Elija Condicion IVA'
        })
      }
    },
    banks: {
      deep: true,
      handler () {
        this.banksItems = this.transformToSelect(this.banks, 'name', {
          value: '',
          text: 'Elija Banco'
        })
      }
    },
    bankAccountTypes: {
      deep: true,
      handler () {
        this.bankAccountTypesItems = this.transformToSelect(this.bankAccountTypes, 'name', {
          value: '',
          text: 'Elija tipo de cuenta bancaria'
        })
      }
    },
    currencies: {
      deep: true,
      handler () {
        this.currenciesItems = this.transformToSelect(this.currencies, 'name', {
          value: '',
          text: 'Elija Moneda'
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.customer-info {
  .col-left {
    flex: 2;
  }

  .col-right {
    flex: 1;
    position: sticky;
    top: 0;
  }
}

.dashed {
  border: 1px dashed;
  opacity: 0.1;
}

.save-btn {
  position: absolute;
  top: 50%;
  right: 2rem;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}

.bank-account-container {
  padding-bottom: 2rem;

  &:not(:last-child) {
    border-bottom: 1px solid $line-grey;
  }
}

.vue-tel-input-custom {
  border: none;
  border-radius: 0;
  border-bottom: 1px solid rgba(255,255,255,0.7);
  padding-top: 12px;
  margin-top: 3px;

  :deep(.vue-tel-input-custom-class) {
    color: rgba(255,255,255,0.7);
  }

  :deep(.vti__dropdown.open) {
    background: #121212;
  }

  :deep(.vti__dropdown-list) {
    background: #121212;
    .vti__dropdown-item.highlighted {
      background: #1E1E1E;
    }
  }
}
</style>
